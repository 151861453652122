import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import MuiIconButton, { IconButtonProps } from '@material-ui/core/IconButton';

import { FlowThemeInterface } from '../../styles/theme';
import { IconProps } from '../icons';

interface StyledIconButtonProps {
  active?: number;
}

const IconButton = styled(MuiIconButton as React.FunctionComponent<
  IconButtonProps & StyledIconButtonProps
>)`
  && {
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: ${({ active }) => (active ? "#e2e2e2" : "transparent")};
    height: 50px;
    width: 50px;
  }
`;

interface OwnProps {
  linkTo: string;
  iconComponent: React.FunctionComponent<IconProps>;
  currentRoute: string;
  activeColor?: string;
  defaultColor?: string;
  theme: FlowThemeInterface;
}

type AllProps = OwnProps;

const SideBarButton: React.FunctionComponent<AllProps> = ({
  linkTo,
  iconComponent,
  currentRoute,
  defaultColor,
  activeColor,
  ...props
}) => {
  return (
    <Link to={linkTo}>
      <IconButton
        aria-label="Communication"
        active={currentRoute.includes(linkTo) ? 1 : 0}
        {...props}
      >
        {iconComponent({
          nativeColor: currentRoute.includes(linkTo)
            ? activeColor
            : defaultColor
        })}
      </IconButton>
    </Link>
  );
};

const StyledSideBarButton = styled(SideBarButton).attrs((props: AllProps) => ({
  activeColor: props.theme.colors.grey3,
  defaultColor: props.theme.colors.grey3
}))``;

export default StyledSideBarButton;
