import { omit } from 'lodash';
import * as React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import withWidth, { isWidthDown, isWidthUp, WithWidth } from '@material-ui/core/withWidth';

import { FlowThemeInterface } from '../../styles/theme';
import styled, { mediaDown, withTheme } from '../../utils/styledComponents';
import { HeaderContextProvider } from '../context/HeaderContext';
import LoadingPage from '../ui/LoadingPage';
import { HeaderInterface } from './Header';

// import Header from './Header';

interface OwnProps {
  headerComponent?: React.ReactElement<HeaderInterface>;
  theme: FlowThemeInterface;
  loading?: boolean;
  headerHidden?:
    | "mdUp"
    | "lgDown"
    | "lgUp"
    | "mdDown"
    | "mdUp"
    | "smDown"
    | "smUp"
    | "xlDown"
    | "xlUp"
    | "xsDown"
    | "xsUp";
  embeddedInDialog?: boolean;
}

type AllProps = GridProps & WithWidth & OwnProps;

interface State {
  readonly isHeaderHidden: boolean;
}

const ContainerGrid = styled(Grid as React.FunctionComponent<GridProps>)`
  position: relative;
`;

const ScrollContent = styled(
  (props: GridProps & { hasHeader: boolean | string }) => (
    <Grid {...omit(props, ["hasHeader"])} />
  )
)`
  min-height: 100%;
  padding: 32px 56px;
  background: ${({ theme }) => theme.colors.pageBg};
  padding-top: ${({ hasHeader }) => (hasHeader ? "82px" : "56px")};

  ${mediaDown.lg`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 30px;
`}

  ${mediaDown.md`
   padding-left: 20px;
   padding-right: 20px;
   padding-bottom: 30px;
`}
`;

const ScrollSection = styled.div<{ embeddedInDialog?: boolean }>`
  ${({ embeddedInDialog }) => embeddedInDialog ? "" : `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  `}
`;

class Body extends React.Component<AllProps, State> {
  public readonly state: State = {
    isHeaderHidden: false
  };
  protected prev: any;

  public render() {
    const {
      children,
      headerComponent,
      headerHidden,
      loading,
      theme,
      width,
      ...props
    } = this.props;

    const hiddenProp = {};
    let headerPadding = true;
    if (headerHidden) {
      hiddenProp[headerHidden] = true;
      if (headerHidden.includes("Up")) {
        const breakpoint = headerHidden.replace("Up", "");
        headerPadding = !isWidthUp(breakpoint as Breakpoint, width);
      } else if (headerHidden.includes("Down")) {
        const breakpoint = headerHidden.replace("Down", "");
        headerPadding = !isWidthDown(breakpoint as Breakpoint, width);
      }
    }

    return (
      <HeaderContextProvider value={this.state}>
        <ContainerGrid item={true} xs={true}>
          <Hidden {...hiddenProp}>
            {headerComponent
              ? React.cloneElement(headerComponent, {
                  backgroundColor: theme.colors.pageBg
                })
              : null}
          </Hidden>
          <ScrollSection embeddedInDialog={this.props.embeddedInDialog} ref={this.bodyDidMount}>
            <ScrollContent
              container={true}
              direction="column"
              {...props}
              hasHeader={headerComponent && headerPadding ? true : false}
            >
              {loading ? <LoadingPage /> : children}
            </ScrollContent>
          </ScrollSection>
        </ContainerGrid>
      </HeaderContextProvider>
    );
  }

  private bodyDidMount = (node: HTMLDivElement) => {
    if (node) {
      node.addEventListener("scroll", this.scrolled);
    }
  };

  private scrolled = (evt: any) => {
    const { isHeaderHidden } = this.state;

    if (evt.target.scrollTop > this.prev) {
      if (!isHeaderHidden) {
        this.setState({ isHeaderHidden: true });
      }
    } else {
      if (isHeaderHidden) {
        this.setState({ isHeaderHidden: false });
      }
    }
    this.prev = evt.target.scrollTop;
  };
}

export default withWidth()(withTheme(Body));
