import * as React from "react";

import MuiAvatar, { AvatarProps } from "@material-ui/core/Avatar";

import logo from "../../assets/images/brand/circleLogo.png";
import styled from "../../utils/styledComponents";
import SideBarButton from "../buttons/SideBarButton";
import {
  CommunicationIcon,
  NavContentIcon,
  ProfileIcon,
  CampaignIcon,
  AnalyticsIcon,
  SubscriptionPlansIcon,
} from "../icons";
import PartnersIcon from "../icons/PartnersIcon";

const StyledSideBar = styled.div`
  flex: 1;
  justify-content: center;
  align-content: center;
  background-color: ${({ theme }) => theme.colors.grey1};
  height: 100%;
`;

const Wrapper = styled.div`
  top: 0;
  position: fixed;
`;

const Logo = styled(MuiAvatar as React.FunctionComponent<AvatarProps>)`
  && {
    width: 20px;
    height: 20px;
  }
`;

const LogoWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  flex-direction: column;
`;

interface OwnProps {
  activeRoute: string;
  whiteLabelEnabled: boolean;
  referralPartnerEnabled: boolean;
  hasAdminRights: boolean | undefined;
}

const SideBar: React.FunctionComponent<OwnProps> = ({
  activeRoute,
  whiteLabelEnabled,
  referralPartnerEnabled,
  hasAdminRights,
}) => {
  return (
    <StyledSideBar>
      <Wrapper>
        <LogoWrapper>{!whiteLabelEnabled && <Logo src={logo} />}</LogoWrapper>
        <MenuItemsWrapper>
          <SideBarButton
            iconComponent={CampaignIcon}
            linkTo="/campaigns"
            currentRoute={activeRoute}
          />

          <SideBarButton
            iconComponent={AnalyticsIcon}
            linkTo="/dashboard"
            currentRoute={activeRoute}
          />

          {/* <SideBarButton
          iconComponent={TenantsIcon}
          linkTo="/tenants"
          currentRoute={activeRoute}
        /> */}
          <SideBarButton
            iconComponent={NavContentIcon}
            linkTo="/content"
            currentRoute={activeRoute}
          />
          <SideBarButton
            iconComponent={CommunicationIcon}
            linkTo="/chats"
            currentRoute={activeRoute}
          />
          {referralPartnerEnabled && (
            <SideBarButton
              iconComponent={PartnersIcon}
              linkTo="/partners"
              currentRoute={activeRoute}
            />
          )}

          {hasAdminRights && (
            <SideBarButton
              iconComponent={SubscriptionPlansIcon}
              linkTo="/subscription-plans"
              currentRoute={activeRoute}
            />
          )}

          <SideBarButton
            iconComponent={ProfileIcon}
            linkTo="/account"
            currentRoute={activeRoute}
          />
        </MenuItemsWrapper>
      </Wrapper>
    </StyledSideBar>
  );
};

export default SideBar;
