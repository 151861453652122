import { get, isEmpty } from 'lodash';
import { AuthenticatedUser } from '../store/auth';
import { OrganisationMembers } from '../store/organisation';

export const getMemberByPath = <T>(members: OrganisationMembers[], path: string, value: T): AuthenticatedUser | null => {
  if (isEmpty(members)) return null;

  const memberAgent = members.find(member => get(member, path) === value);

  return get(memberAgent, 'user', null);
};

export const DropdownIndexes = {
  engagementMaximiserSettings: 0,
  enquiryNotificationSettings: 1,
  emailNotification: 2,
  smsNotification: 3,
  localeSettings: 4,
  colorSettings: 5,
  paymentMethods: 6,
  addressSettings: 7,
  showPartners: 8,
  partnerReferrals: 9,
  campaignSettings: 10
};